<template>
  <v-container>
    <v-overlay :value="loadingAssets">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="addRuleDialog" persistent max-width="800">
      <search-rule
        @add-rule="addRule"
        @close-dialog="closeDialog"
      ></search-rule>
    </v-dialog>

    <v-dialog v-model="loadRuleDialog" persistent max-width="800">
      <v-card elevation="10">
        <v-card-title>Carica ricerca</v-card-title>
        <v-card-text>
          <br />
          <div v-if="savedSearches.length > 0">
            <v-row v-for="(search, index) of savedSearches" :key="index">
              <v-col cols="6"
                ><v-btn plain @click="setSearch(index)">
                  - {{ search.TITLE }}</v-btn
                ></v-col
              ><v-spacer></v-spacer
              ><v-col cols="1"
                ><v-btn icon @click="removeSearch(index)"
                  ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </div>
          <v-row v-else> Nessuna ricerca salvata </v-row>
          <br />
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <v-btn @click="loadRuleDialog = false">Annulla</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="saveRuleDialog" persistent max-width="800">
      <v-card elevation="10">
        <v-card-title>Salva ricerca</v-card-title>
        <v-card-text>
          <v-text-field
            type="text"
            v-model="searchTitle"
            label="titolo"
          ></v-text-field>
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <v-btn :disabled="searchTitle.length === 0" @click="saveSearch()"
              >Salva</v-btn
            >
            <v-btn @click="saveRuleDialog = false">Annulla</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="12" min-height="600">
      <v-card-title>Ricerca beni</v-card-title>

      <v-card-text>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addRuleDialog = true" v-bind="attrs" v-on="on"
              >Aggiungi criterio</v-btn
            >
          </template>
          <span>Seleziona una serie di regole per la ricerca</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="loadSearches()"
              :disabled="loadingSearches"
              v-bind="attrs"
              v-on="on"
              >Carica ricerca</v-btn
            >
          </template>
          <span>Carica una ricerca precedentemente salvata</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="query.children.length > 0"
              @click="saveRuleDialog = true"
              v-bind="attrs"
              v-on="on"
              >Salva ricerca</v-btn
            >
          </template>
          <span>Salva la ricerca corrente</span>
        </v-tooltip>
      </v-card-text>
      <v-card-text v-if="query.children.length === 0">
        <br />
        <h3>Nessun criterio definito</h3>
      </v-card-text>
      <v-card-text v-else>
        <br />
        <v-row v-for="(item, index) in query.children" :key="index">
          <v-col cols="4"
            ><h3>
              {{ item.fieldText }} {{ item.operatorText }} {{ item.valueText }}
            </h3></v-col
          >
          <v-col cols="1">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="deleteCriterium(index)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
              <span>Elimina criterio</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="query.children.length > 0" @click="searchAssets"
          >Cerca</v-btn
        >
      </v-card-actions>
      <v-card-text>
        <span v-if="searched">Trovati {{ foundAssets.length }} beni</span>
        <v-data-table
          class="fixedtable"
          v-show="searched"
          max-width="100%"
          elevation="10"
          :headers="headers"
          :items="foundAssets"
          item-key="ID"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Beni per pagina',
            itemsPerPageAllText: 'Tutti i beni',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Beni per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessun bene </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessun bene </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <span class="nowrap">
              <v-tooltip v-if="item.Pictures.length > 0" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on">mdi-camera</v-icon>
                </template>
                <v-card>
                  <v-img
                    class="ma-2"
                    height="300"
                    max-width="500"
                    contain
                    :src="imageUrl(item.Pictures[0].id)"
                  >
                  </v-img>
                </v-card>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <router-link
                    v-bind="attrs"
                    v-on="on"
                    :to="'/assets/' + item.ID"
                  >
                    <v-icon small> mdi-eye-outline </v-icon>
                  </router-link>
                </template>
                <span>Visualizza</span>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:item.ID="{ item }">
            <span
              :class="
                item.DT_BUONO_SCARICO ? 'text--disabled nowrap' : 'nowrap'
              "
            >
              {{ item.ID }}
            </span>
          </template>
          <template v-slot:item.MARCA="{ item }">
            <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
              {{ item.MARCA }}
            </span>
          </template>
          <template v-slot:item.DS_BENE="{ item }">
            <span :class="item.DT_BUONO_SCARICO ? 'text--disabled wrap' : 'wrap'">
              {{ item.DS_BENE }}
            </span>
          </template>
          <template v-slot:item.DT_BUONO_CARICO="{ item }">
            <span
              :class="
                item.DT_BUONO_SCARICO ? 'text--disabled nowrap' : 'nowrap'
              "
            >
              {{ item.DT_BUONO_CARICO }}
            </span>
          </template>
          <template v-slot:item.OWNER="{ item }">
            <span :class="item.DT_BUONO_SCARICO ? 'text--disabled' : ''">
              {{ extractFullUser(item) }}
            </span>
          </template>
          <template v-slot:item.CURRENT_POSITION="{ item }">
            <span
              :class="
                item.DT_BUONO_SCARICO ? 'text--disabled nowrap' : 'nowrap'
              "
            >
              {{ item.CURRENT_POSITION }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SearchRule from "../components/SearchRule.vue";
const moment = require("moment-timezone");

export default {
  name: "AssetsSearch",
  components: { SearchRule },
  data: () => {
    return {
      query: {
        searchType: "and",
        type: "group",
        children: [],
      },
      savedSearches: [],
      // savedSearches: [
      //   {
      //     title: "blah blah",
      //     query: {
      //       searchType: "and",
      //       type: "group",
      //       children: [
      //         {
      //           field: "removed",
      //           operator: "isnot",
      //           value: "scaricato",
      //           fieldText: "Il bene",
      //           valueText: "scaricato",
      //           operatorText: "non è stato",
      //         },
      //         {
      //           field: "serial",
      //           operator: "contains",
      //           value: "foobar",
      //           fieldText: "Numero di serie",
      //           valueText: "foobar",
      //           operatorText: "contiene",
      //         },
      //       ],
      //     },
      //   },
      // ],
      search: "",
      searched: false,
      foundAssets: [],
      loadingAssets: false,
      loadingSearches: false,
      permSnackbar: false,
      snackbarColor: "success",
      timeout: -1,
      errorText: "",
      emptyRule: {
        field: null,
        fieldText: null,
        operator: null,
        operatorText: null,
        value: null,
        valueText: null,
      },
      addRuleDialog: false,
      loadRuleDialog: false,
      saveRuleDialog: false,
      searchTitle: "",
      headers: [
        {
          text: "Inventario",
          align: "start",
          sortable: false,
          value: "ID",
        },
        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "MARCA",
        },
        {
          text: "Descrizione",
          align: "start",
          sortable: true,
          value: "DS_BENE",
        },
        {
          text: "Carico",
          align: "start",
          sortable: true,
          value: "DT_BUONO_CARICO",
        },
        {
          text: "Assegnatario",
          align: "start",
          sortable: false,
          value: "OWNER",
        },
        {
          text: "Locazione",
          align: "start",
          sortable: false,
          value: "CURRENT_POSITION",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "id",
        },
      ],
    };
  },
  created: function () {
    if (!this.$assetSearchQuery) {
      Vue.prototype.$assetSearchQuery = this.query;
    } else {
      this.query = this.$assetSearchQuery;
      if (this.query.children.length > 0) {
        this.searchAssets();
      }
    }
    if (this.$route.fullPath.endsWith("/last")) {
      let d = new Date();
      d.setMonth(d.getMonth() - 1);
      let dateToSearch = moment(d)
        .tz("Europe/Rome")
        .locale("IT")
        .format("YYYY-MM-DD");
      console.log(dateToSearch);
      this.query = {
        searchType: "and",
        type: "group",
        children: [
          {
            field: "datacarico",
            operator: "gt",
            value: dateToSearch,
            fieldText: "Data buono di carico",
            operatorText: "è successiva al",
            valueText: dateToSearch,
          },
        ],
      };
      this.searchAssets();
    } else if (this.$route.params.year && this.$route.params.number) {
      this.query = {
        searchType: "and",
        type: "group",
        children: [
          {
            field: "buonocarico",
            operator: "eq",
            value: this.$route.params.number,
            fieldText: "Numero buono carico",
            operatorText: "è uguale a",
            valueText: this.$route.params.number,
          },
          {
            field: "annocarico",
            operator: "eq",
            value: this.$route.params.year,
            fieldText: "Anno di carico",
            operatorText: "è uguale a",
            valueText: this.$route.params.year,
          },
        ],
      };
      this.searchAssets();
    }
  },
  computed: {
    // headers() {
      // return [
      //   {
      //     text: "Inventario",
      //     align: "start",
      //     sortable: false,
      //     value: "ID",
      //   },
      //   {
      //     text: "Marca",
      //     align: "start",
      //     sortable: true,
      //     value: "MARCA",
      //   },
      //   {
      //     text: "Descrizione",
      //     align: "start",
      //     sortable: true,
      //     value: "DS_BENE",
      //   },
      //   {
      //     text: "Carico",
      //     align: "start",
      //     sortable: true,
      //     value: "DT_BUONO_CARICO",
      //   },
      //   {
      //     text: "Assegnatario",
      //     align: "start",
      //     sortable: false,
      //     value: "OWNER",
      //   },
      //   {
      //     text: "Locazione",
      //     align: "start",
      //     sortable: false,
      //     value: "CURRENT_POSITION",
      //   },
      //   {
      //     text: "",
      //     align: "end",
      //     sortable: false,
      //     value: "id",
      //   },
      // ];
    // },
  },
  methods: {
    imageUrl(id) {
      return process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + id;
    },
    saveSearch() {
      // Save on backend
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/assets/searches",
          { query: this.query, title: this.searchTitle },
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          // this.loadRuleDialog = true;
        })
        .catch((err) => {
          // this.loadRuleDialog = true;
          // TODO: show error message
          console.log(err);
        });

      this.saveRuleDialog = false;
    },
    setSearch(index) {
      this.query = JSON.parse(this.savedSearches[index].QUERY);
      this.loadRuleDialog = false;
    },
    removeSearch(index) {
      // TODO delete search from backend
      this.$http
        .delete(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/searches/" +
            this.savedSearches[index].ID,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loadSearches();
        })
        .catch((err) => {
          // this.loadRuleDialog = true;
          console.log(err);
        });
    },
    loadSearches() {
      // Load saved searches from backend
      this.loadingSearches = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/assets/searches", {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.savedSearches = response.data;
          this.loadRuleDialog = true;
          this.loadingSearches = false;
        })
        .catch((err) => {
          // this.loadRuleDialog = true;
          this.loadingSearches = false;
          console.log(err);
        });
    },
    deleteCriterium(index) {
      this.query.children.splice(index, 1);
      this.foundAssets = [];
    },
    extractFullUser(item) {
      if (!item.Users) {
        return item.MATRICOLA;
      }
      let matricola = item.CURRENT_OWNER;
      for (let user of item.Users) {
        if (user.MATRICOLA === matricola) {
          return user.COGNOME + " " + user.NOME;
        }
      }
      return matricola;
    },
    closeDialog() {
      this.addRuleDialog = false;
    },
    addRule(rule) {
      this.query.children.push({ ...rule });
      this.addRuleDialog = false;
    },
    searchAssets() {
      this.loadingAssets = true;
      Vue.prototype.$assetSearchQuery = this.query;
      console.log("searchAssets");
      console.log(this.$assetSearchQuery);
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API + "/assets/search",
          this.query,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          for (let asset of response.data.assets) {
            asset.OWNER = this.extractFullUser(asset);
          }
          this.foundAssets = response.data.assets;
          this.loadingAssets = false;
          this.searched = true;
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          this.loadingAssets = false;
        });
    },
  },
};
</script>
<style scoped>
.nowrap {
  white-space: nowrap;
}
.wrap {
  white-space: normal;
}
</style>