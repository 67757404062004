<template>
  <v-container>
    <v-dialog
      v-model="dateDialog"
      width="500"
    >
    <v-card>
      <v-card-title>Scelta data</v-card-title>
      <v-card-text>
        <v-date-picker v-model="selectedValue" locale="it-it" full-width @change="dateDialog=false"></v-date-picker>
      </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="dateDialog=false">Annulla</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <v-card elevation="10">
      <v-card-title>Nuovo criterio</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="selectedType"
              :items="searchTypes"
              item-text="fieldName"
              item-value="field"
              label="Entità"
              @change="resetFields"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-if="selectedType"
              v-model="selectedOperator"
              :items="searchOperators"
              item-text="operatorName"
              item-value="operator"
              label="Operatore"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                (selectedInputType === 'text' ||
                  selectedInputType === 'number' ||
                  selectedInputType === 'float')
              "
              :type="
                selectedInputType === 'float' || selectedInputType === 'number'
                  ? 'number'
                  : 'text'
              "
              :step="
                selectedInputType === 'float'
                  ? '0.01'
                  : selectedInputType === 'number'
                  ? '1'
                  : ''
              "
              v-model="selectedValue"
              :label="selectedLabel"
            >
            </v-text-field>
            <v-text-field
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'date'
              "
              :readonly="true"
              @click="dateDialog=true"
              v-model="selectedValue"
              :label="selectedLabel"
            >
            <template slot="append"><v-btn icon small @click="dateDialog=true"><v-icon>mdi-calendar-month-outline</v-icon></v-btn></template>
            </v-text-field>
            <div
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'removed'
              "
            >
              <br />scaricato
            </div>
            <div
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'f_dotaz'
              "
            >
              <br />Fondo dotazione dipartimento
            </div>
            <v-select
            v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'list'
              "
              v-model="selectedValue"
              :items="itemlist"
            >
            </v-select>

            <v-autocomplete
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'user'
              "
              v-model="selectedValue"
              :loading="usersLoading"
              :items="users"
              :search-input.sync="searchuser"
              item-text="text"
              item-value="MATRICOLA"
              cache-items
              class="mx-4"
              flat
              hide-no-data
              hide-details
              return-object
              label="Ricerca persona"
            ></v-autocomplete>
            <v-autocomplete
              v-if="
                selectedType &&
                selectedOperator &&
                selectedInputType &&
                selectedInputType === 'room'
              "
              v-model="selectedValue"
              :loading="roomsLoading"
              :items="rooms"
              :search-input.sync="searchroom"
              item-text="text"
              item-value="ID"
              cache-items
              class="mx-4"
              flat
              hide-no-data
              hide-details
              return-object
              label="Ricerca stanza"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-spacer></v-spacer>
          <v-btn :disabled="!readyToAdd" v-if="readyToAdd" @click="addRule"
            >Aggiungi regola</v-btn
          >

          <v-btn @click="$emit('close-dialog')">Annulla</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "SearchRule",
  props: {
    rule: {
      type: Object,
      required: false,
      default: function () {
        return {
          field: null,
          fieldText: null,
          operator: null,
          operatorText: null,
          value: null,
          valueText: null,
        };
      },
    },
  },
  data: () => {
    return {
      dateDialog: false,
      currentRule: {
        field: null,
        operator: null,
        value: null,
      },
      roomsLoading: false,
      usersLoading: false,
      rooms: [],
      users: [],
      itemlist: [],
      searchroom: null,
      searchuser: null,
      selectedType: null,
      selectedOperator: null,
      searchOperators: null,
      selectedInputType: null,
      selectedValue: null,
      selectedLabel: null,
      searchTypes: [
        {
          field: "inventario",
          fieldName: "Numero di inventario",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "contains",
              operatorName: "contiene",
            },
          ],
          type: "text",
          label: "Inventario",
        },
        {
          field: "marca",
          fieldName: "Marca",
          operators: [
            {
              operator: "contains",
              operatorName: "contiene",
            },
          ],
          type: "text",
          label: "Marca",
        },
        {
          field: "descrizione",
          fieldName: "Descrizione",
          operators: [
            {
              operator: "contains",
              operatorName: "contiene",
            },
            {
              operator: "doesnotcontain",
              operatorName: "non contiene",
            },
          ],
          type: "text",
          label: "Descrizione",
        },
        {
          field: "etichetta",
          fieldName: "Codice etichetta",
          operators: [
            {
              operator: "contains",
              operatorName: "contiene",
            },
            {
              operator: "doesnotcontain",
              operatorName: "non contiene",
            },
          ],
          type: "text",
          label: "Descrizione",
        },
        {
          field: "serial",
          fieldName: "Numero di serie",
          operators: [
            {
              operator: "contains",
              operatorName: "contiene",
            },
          ],
          type: "text",
          label: "Numero di serie",
        },
        {
          field: "valore",
          fieldName: "Valore",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è minore di",
            },
            {
              operator: "gt",
              operatorName: "è maggiore di",
            },
          ],
          type: "float",
          label: "€",
        },
        {
          field: "removed",
          fieldName: "Il bene",
          operators: [
            {
              operator: "is",
              operatorName: "è stato",
            },
            {
              operator: "isnot",
              operatorName: "non è stato",
            },
          ],
          type: "removed",
          label: "Scaricato",
        },
        {
          field: "projecttype",
          fieldName: "Il Tipo di progetto",
          operators: [
            {
              operator: "is",
              operatorName: "è",
            },
            {
              operator: "isnot",
              operatorName: "non è",
            },
          ],
          type: "f_dotaz",
          label: "Fondo dotazione Dip.",
        },
        {
          field: "project",
          fieldName: "Il progetto (fondo)",
          operators: [
            {
              operator: "contains",
              operatorName: "contiene",
            },
          ],
          type: "text",
          label: "Progetto",
        },
        {
          field: "ram",
          fieldName: "La RAM",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è minore di",
            },
            {
              operator: "gt",
              operatorName: "è maggiore di",
            },
          ],
          type: "number",
          label: "GB",
        },
        {
          field: "cpucore",
          fieldName: "Il numero di CPU core",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è minore di",
            },
            {
              operator: "gt",
              operatorName: "è maggiore di",
            },
          ],
          type: "number",
          label: "core",
        },
        {
          field: "screensize",
          fieldName: "La dimensione (pollici)",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è minore di",
            },
            {
              operator: "gt",
              operatorName: "è maggiore di",
            },
          ],
          type: "number",
          label: "pollici",
        },
        {
          field: "storagesize",
          fieldName: "La dimensione storage (GB)",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è minore di",
            },
            {
              operator: "gt",
              operatorName: "è maggiore di",
            },
          ],
          type: "number",
          label: "GB",
        },
        {
          field: "buonocarico",
          fieldName: "Numero buono carico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
          ],
          type: "number",
          label: "Numero",
        },
        {
          field: "datacarico",
          fieldName: "Data buono di carico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è precedente al",
            },
            {
              operator: "gt",
              operatorName: "è successiva al",
            },
          ],
          type: "date",
          label: "Data",
        },
        {
          field: "annocarico",
          fieldName: "Anno di carico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è precedente al",
            },
            {
              operator: "gt",
              operatorName: "è successivo al",
            },
          ],
          type: "number",
          label: "Anno",
        },
        {
          field: "buonoscarico",
          fieldName: "Numero buono scarico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
          ],
          type: "number",
          label: "Numero",
        },
        {
          field: "datascarico",
          fieldName: "Data buono di scarico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è precedente al",
            },
            {
              operator: "gt",
              operatorName: "è successiva al",
            },
          ],
          type: "date",
          label: "Data",
        },
        {
          field: "annoscarico",
          fieldName: "Anno di scarico",
          operators: [
            {
              operator: "eq",
              operatorName: "è uguale a",
            },
            {
              operator: "lt",
              operatorName: "è precedente al",
            },
            {
              operator: "gt",
              operatorName: "è successivo al",
            },
          ],
          type: "number",
          label: "Anno",
        },
        {
          field: "user",
          fieldName: "Assegnatario",
          operators: [
            {
              operator: "eq",
              operatorName: "è",
            },
            {
              operator: "noteq",
              operatorName: "non è",
            },
          ],
          type: "user",
          label: "Assegnatario",
        },
        {
          field: "room",
          fieldName: "Collocazione",
          operators: [
            {
              operator: "eq",
              operatorName: "è",
            },
            {
              operator: "noteq",
              operatorName: "non è",
            },
          ],
          type: "room",
          label: "Collocazione",
        },
        {
          field: "techtype",
          fieldName: "Tipo di attrezzatura",
          operators: [
            {
              operator: "eq",
              operatorName: "è",
            },
            {
              operator: "noteq",
              operatorName: "non è",
            },
          ],
          type: "list",
          label: "Tipo attrezzatura",
          itemlist: ["LAPTOP", "PC", "SERVER", "TABLET", "MONITOR", "PRINTER"],
        },
      ],
    };
  },
  watch: {
    searchroom(val) {
      val && val !== this.selectedRoom && this.queryRooms(val);
    },
    searchuser(val) {
      val && val !== this.selectedUser && this.queryUsers(val);
    },
    selectedType(val) {
      if (val === "removed") {
        this.selectedValue = "scaricato";
      }
      if (val === "projecttype") {
        this.selectedValue = "Fondo dotazione Dipartimento";
      }
      for (let searchType of this.searchTypes) {
        if (searchType.field === this.selectedType) {
          this.currentRule.field = this.selectedType;
          this.currentRule.fieldText = searchType.fieldName;
          this.selectedInputType = searchType.type;
          this.searchOperators = searchType.operators;
          // if(this.searchOperators.length === 1) {
          //   this.selectedOperator = this.searchOperators[0];
          //   this.selectedValue = '';
          // }
          this.selectedLabel = searchType.label;
          this.itemlist = searchType.itemlist;
        }
      }
    },
    selectedOperator() {
      for (let searchType of this.searchTypes) {
        if (searchType.field === this.selectedType) {
          this.currentRule.operator = this.selectedOperator;
          for (let operator of searchType.operators) {
            if (operator.operator === this.selectedOperator) {
              this.currentRule.operatorText = operator.operatorName;
            }
          }
        }
      }
    },
    selectedValue(val) {
      if (this.selectedType === "user") {
        this.currentRule.value = val.MATRICOLA;
        this.currentRule.valueText = val.text;
      } else if (this.selectedType === "room") {
        this.currentRule.value = val.ID;
        this.currentRule.valueText = val.text;
      } else {
        this.currentRule.value = val;
        this.currentRule.valueText = val;
      }
      // Prendi il testo e poi
      // mettilo in this.currentRule.valueText
    },
  },
  computed: {
    readyToAdd() {
      return (
        (this.selectedValue ||
          this.selectedType === "removed" ||
          this.selectedType === "projecttype") &&
        this.selectedValue !== "" &&
        this.selectedType &&
        this.selectedType !== "" &&
        this.selectedOperator &&
        this.selectedOperator !== ""
      );
    },
  },
  methods: {
    addRule() {
      this.$emit("add-rule", this.currentRule);
      this.selectedOperator = null;
      this.selectedType = null;
      this.selectedValue = null;
    },
    queryRooms(v) {
      this.roomsLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/rooms/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let room of response.data) {
            let name = room.ID;
            if (room.LOCAL_NAME) {
              name += " (" + room.LOCAL_NAME + ")";
            } else if (room.RM_NAME) {
              name += " (" + room.RM_NAME + ")";
            }
            room.text = name;
          }
          this.rooms = response.data;
          this.roomsLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
      // Simulated ajax query
      // setTimeout(() => {
      //   this.items = this.states.filter(e => {
      //     return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
      //   })
      //   this.loading = false
      // }, 500)

      // TODO load rooms from backend
      // },
    },
    queryUsers(v) {
      this.usersLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
          }
          this.users = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
    resetFields() {
      console.log('Resetting fields..')
      this.selectedOperator = null;
      this.selectedValue = null;
    }
  },
};
</script>